.react-multiple-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.2);
}

.react-simple-image-viewer__modal {
  z-index: 2000 !important;
}

.DateRangePicker__CalendarSelection {
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.DateRangePicker__CalendarHighlight--single {
  border-color: var(--color-secondary) !important;
}

.atom-overlay {
  z-index: 2000 !important;
}

.wcm-overlay {
  z-index: 2000 !important;
}
